import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useEffect, useState} from "react";
import {useRouter} from "next/router";
import {useAuth} from "../hooks/auth";
import {Copyright} from "../layouts";
import {Alert, AlertTitle} from "@mui/material";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Poppins',
            'sans-serif',
        ].join(','),
        fontSize: 12
    },
});

export default function login() {

    const router = useRouter()

    const {login} = useAuth({
        middleware: 'guest',
        redirectIfAuthenticated: '/',
    })

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)

    useEffect(() => {
        if (router.query.reset?.length > 0 && errors.length === 0) {
            setStatus(atob(router.query.reset))
        } else {
            setStatus(null)
        }
    })

    const submitForm = async event => {
        event.preventDefault()
        await login({email, password, setErrors, setStatus})
    }


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" sx={{height: "100vh", display: "flex"}}>
                <CssBaseline/>
                <Box sx={{my: "auto"}}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: 'secondary'}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5" sx={{fontWeight: 700}}>
                            Inicia Sesión
                        </Typography>

                        <>
                            {status && (
                                <Typography color="green">
                                    {status}
                                </Typography>
                            )}
                        </>

                        <>
                            {errors.length > 0 && (
                                <Alert severity="error" sx={{ mt: 3 }}>
                                    <AlertTitle>Algo salió mal</AlertTitle>
                                    <ul>
                                        {errors.map(error => (
                                            <li key={error}>
                                                <Typography color={"danger"}>
                                                    {error}
                                                </Typography></li>
                                        ))}
                                    </ul>
                                </Alert>
                            )}
                        </>

                        <Box component="form" onSubmit={submitForm} noValidate sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Correo"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" name="remember" color="primary"/>}
                                label="Recuérdame"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Ingresar
                            </Button>
                        </Box>
                    </Box>
                    <Copyright/>
                </Box>
            </Container>
        </ThemeProvider>
    );
}